import { useMutation } from '@tanstack/vue-query'
import type { ILeaseManagerExportResponse } from '@export'

export const useMutationExportToLM = (divisionId: string) => {
  return useMutation({
    mutationFn: (leaseId: string) =>
      fetchAPI<ILeaseManagerExportResponse>(
        `/companies/${divisionId}/leases/${leaseId}/export/leaseManager`,
        {
          method: 'POST',
        },
      ),
  })
}
